<template>
  <AppTooltip>
    <template #text>
      {{ t('common.documentation') }}
    </template>

    <a
      class="info__link"
      :href="link"
      tabindex="0"
      :aria-label="t('ariaLabel.learnMore')"
      rel="noopener noreferrer"
      target="_blank"
      @keypress.space="onSpacePress"
    >
      <AppIcon class="question" name="info-question-circle-filled" size="20px" />
      <AppIcon class="arrow" name="external-link" size="18px" />
    </a>
  </AppTooltip>
</template>

<script>
import AppTooltip from '@/components/AppTooltip.vue';

export default {
  name: 'MoreLink',
  components: {
    AppTooltip,
  },
  setup() {
    const link = `https://${process.env.VUE_APP_DOCS_URL}`;

    const onSpacePress = (e) => {
      e.preventDefault();
      window.open(link, '_blank').focus();
    };

    return { link, onSpacePress };
  },
};
</script>

<style scoped lang="scss">
.info__link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  @include transition-base(background);

  @include focus-visible;

  :deep(.icon) {
    position: absolute;
  }

  &:hover {
    background: #F2F2F2;
    @include transition-base(background);

    .question {
      opacity: 0;
    }

    .arrow {
      opacity: 1;
    }
  }

  .question {
    opacity: 1;
    @include transition-base(opacity);
  }

  .arrow {
    opacity: 0;
    @include transition-base(opacity);
  }
}
</style>
