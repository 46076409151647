<template>
  <div class="heading">
    <div class="header">
      <span class="header-title">
        {{ companyName }}
      </span>
    </div>
    <div class="plan">
      <AppText size="12px" class="plan-badge">
        {{ baseCurrency }}
      </AppText>

      <AppText v-if="subscriptionPlan === 'io'" size="12px" class="plan-badge">
        {{ 'IO' }}
      </AppText>
      <AppText v-else size="12px" class="plan-badge">
        {{ t(`plan.${subscriptionPlan}.title`) }}
      </AppText>

      <AppText v-if="!isLifetimePlan" size="12px" class="plan-info">
        (<i18n-t v-if="isAutoRenew" keypath="header.renews">
          <template #date>
            <DateFormatter size="12px" :date="subscriptionNextPayment" />
          </template>
        </i18n-t>
        <i18n-t v-else keypath="header.expires">
          <template #date>
            <DateFormatter size="12px" :date="subscriptionNextPayment" />
          </template>
        </i18n-t>)
      </AppText>
      <AppText v-else size="12px" class="plan-info">
        ({{ t('cycle.LIFETIME') }})
      </AppText>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';

dayjs.extend(utc);
dayjs.extend(timezone);

const { user, companyName } = useUser();
const { settings } = useSettings();

const baseCurrency = computed(() => user.value.baseCurrency);

const subscriptionPlan = computed(() => user.value.subscription?.plan?.toLowerCase());

const subscriptionNextPayment = computed(() => user.value.subscription?.endDate);
const isLifetimePlan = computed(() => user.value?.subscription?.cycle === 'LIFETIME');

const isAutoRenew = computed(() => settings.value.autoRenewPlan);
</script>

<style scoped lang="scss">
.heading {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  &-title {
    font-size: 18px;
    line-height: 1.2;

    margin-right: 13px;
    @include font-medium;
  }
}

.plan {
  display: flex;
  align-items: center;
  gap: 4px;

  &-badge {
    height: 18px;
    padding: 0 4px;
    background: #F2F2F2;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }

  &-info {
    margin-left: 4px;
    color: var(--color-black-04);
  }
}
</style>
