export const focusNextElement = (selector, infinity = false) => {
  const { focusableElements, currentIndex } = initFocus(selector);

  const nextIndex = currentIndex + 1;

  if (infinity && nextIndex >= focusableElements.length) {
    focusableElements[0].focus();
  } else if (nextIndex < focusableElements.length) {
    focusableElements[nextIndex].focus();
  }
};

export const focusPreviousElement = (selector, infinity = false) => {
  const { focusableElements, currentIndex } = initFocus(selector);

  const prevIndex = currentIndex - 1;

  if (infinity && prevIndex < 0) {
    focusableElements[focusableElements.length - 1].focus();
  } else if (prevIndex >= 0) {
    focusableElements[prevIndex].focus();
  }
};

export const focusFirstElement = (selector) => {
  const { focusableElements } = initFocus(selector);

  if (focusableElements.length > 0) {
    focusableElements[0].focus();
  }
};

const initFocus = (selector) => {
  const activeElement = document.activeElement;
  const focusableElements = Array.from(document.querySelectorAll(selector || 'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')).filter((element) => !element.hasAttribute('disabled'));
  const currentIndex = focusableElements.indexOf(activeElement);

  return { focusableElements, currentIndex };
};
