<template>
  <header class="admin-header">
    <div class="admin-header__body">
      <slot />
    </div>
    <div class="info">
      <MoreLink />
      <AvatarDropdown />
    </div>
  </header>
</template>

<script>
import AvatarDropdown from './AvatarDropdown.vue';
import MoreLink from './MoreLink.vue';

export default {
  name: 'BaseHeader',
  components: {
    AvatarDropdown,
    MoreLink,
  },
};
</script>

<style lang="scss" scoped>
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-black-01);
  height: 80px;
  padding: 0 40px;

  position: fixed;
  top: 0;
  left: $adminSidebarWidth;
  z-index: 4;
  width: calc(100% - #{$adminSidebarWidth});

  background-image: radial-gradient(transparent 1px, #fff 1px);
  background-size: 4px 4px;
  backdrop-filter: saturate(50%) blur(4px);

  .info {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &::after {
    content: '';
    width: 12px;
    height: 79px;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
