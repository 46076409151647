<template>
  <section class="admin-layout" :class="{ maintenance: isMaintenanceOn }">
    <AppSidebar />
    <main class="admin-layout__main">
      <div class="admin-layout__main-wrap">
        <BaseHeader>
          <CompanyNameHeading v-if="isMerchantDashboard" />
          <MainHeading v-else :title="nameFromChild || headerTitle" />
        </BaseHeader>
        <CustomScroll class="main-content-wrapper">
          <router-view v-slot="{ Component }">
            <!-- <Transition name="fade-absolute"> -->
            <component :is="Component" />
            <!-- </Transition> -->
          </router-view>
        </CustomScroll>
      </div>
    </main>

    <ModalToChangePlan
      v-model="isPlanModalOpen"
      :billing-info="{
        balanceUsd: billingInfo.balanceUsd,
        subscription: billingInfo.subscription,
      }"
    />
  </section>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import ModalToChangePlan from '@/components/Modules/ModalToChangePlan';

import { APP_ROLES } from '@/common/data';
import { emitter } from '@/composables/useBus';
import { useAdmin } from '@/composables/useAdmin';
import { useTemporaryStore } from '@/store';
import { useToken } from '@/composables/useToken';
import { router } from '@/router';
import { removeViewportMeta } from '@/composables/useMeta';

import MainHeading from './MainHeading.vue';
import { useSessionKilling } from './useSessionKilling';
import BaseHeader from './BaseHeader.vue';
import CompanyNameHeading from './CompanyNameHeading.vue';
import AppSidebar from './AppSidebar';

export default {
  name: 'MainLayout',
  components: {
    ModalToChangePlan,
    AppSidebar,
    BaseHeader,
    MainHeading,
    CompanyNameHeading,
  },
  setup() {
    removeViewportMeta();

    const { adminSettings, role } = useAdmin();
    const route = useRoute();
    const routeName = computed(() => route.name);

    const { tokenRole } = useToken();

    const isMerchantDashboard = computed(() => routeName.value === 'DASHBOARD' && tokenRole.value === APP_ROLES.MERCHANT);

    if (isMerchantDashboard.value) {
      const { store } = useTemporaryStore();
      store.clearRegistrationState();
    }

    const headerTitle = computed(() => (route.meta.headerTitle ? route.meta.headerTitle : ''));
    const isMaintenanceOn = computed(() => (['superadmin', 'admin'].includes(role?.value) && adminSettings?.value?.isMaintenanceEnabled));

    const nameFromChild = ref('');

    emitter.on('page-title', (title) => {
      if (Array.isArray(title)) {
        nameFromChild.value = title.map((el) => el);
      } else {
        nameFromChild.value = title;
      }
    });

    watch(routeName, () => {
      nameFromChild.value = '';
    });

    useSessionKilling();

    const isPlanModalOpen = ref(false);

    const billingInfo = ref({});
    emitter.on('show-plan-selector', (billingData) => {
      isPlanModalOpen.value = true;
      billingInfo.value = billingData;
      setTimeout(() => {
        router.push({ name: 'BILLING' });
      }, 0);
    });

    return {
      routeName,
      headerTitle,

      nameFromChild,
      isMerchantDashboard,
      isMaintenanceOn,
      isPlanModalOpen,

      billingInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
$height: $adminHeight;

.admin-layout {
  display: flex;
  overflow: hidden;
  position: fixed;

  &.theme-admin.maintenance::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    background: var(--color-error);
    z-index: 99999;
    @include transition-base('all');
  }

  &__main {
    flex-grow: 1;
    height: $height;
    width: calc(100vw - $adminSidebarWidth);

    &-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

</style>
