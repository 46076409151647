<template>
  <FDropdown
    placement="bottom-end"
    class="avatar-dropdown"
    block-class="avatar-dropdown-popper"
  >
    <template #title>
      <AppAvatar :key="profileAvatar" :src="profileAvatar" is-glitch-avatar />
    </template>

    <template #content>
      <DropdownItem
        v-for="item in selectOptions"
        :key="item"
        :class="{ logout: item.isLogOut }"
        no-paddings
        :to="item.to"
        @click="item.to === undefined ? item.fn({ pushToHome: true }) : () => {}"
      >
        <AppIcon
          v-if="item.icon"
          :name="item.icon"
          size="18px"
          class="item-icon"
        />
        <AppText
          uppercase-first-letter
          :opacity="item.isLogOut ? 0.4 : 1"
          class="font-medium"
        >
          {{ t(`header.menu.${item.text}`) }}
        </AppText>
      </DropdownItem>
    </template>
  </FDropdown>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { useAdmin } from '@/composables/useAdmin';
import { useToken } from '@/composables/useToken';
import { useUser } from '@/composables/useUser';
import clickOutside from '@/directives/clickOutside';
import { logout } from '@/api/auth';

export default defineComponent({
  directives: {
    clickOutside,
  },
  components: {
    AppAvatar,
    DropdownItem,
  },
  setup() {
    const { tokenRole } = useToken();

    const merchantOptions = [
      {
        icon: 'coins-stacked',
        type: 'billing',
        text: 'billing',
        to: '/billing',
      },
      {
        icon: 'settings',
        text: 'settings',
        to: '/settings',
      },
      {
        icon: '',
        text: 'logout',
        isLogOut: true,
        fn: logout,
      },
    ];

    const adminOptions = [
      {
        icon: 'log-in',
        text: 'account',
        to: '/profile',
      },
      {
        icon: '',
        text: 'logout',
        isLogOut: true,
        fn: () => logout({ useLogoutEndpoint: false }),
      },
    ];

    const { user } = useUser();
    const selectOptions = computed(() => {
      if (tokenRole.value === 'merchant') {
        return user.value?.subscription?.plan === 'IO' ? merchantOptions.filter((item) => item.type !== 'billing') : merchantOptions;
      }

      return adminOptions;
    });

    const avatarSelect = ref(null);
    const onClick = () => {
      avatarSelect.value.isOpen = !avatarSelect.value.isOpen;
    };

    const hideDropdown = () => {
      avatarSelect.value.isOpen = false;
    };

    const { profileAvatar } = tokenRole.value === 'merchant' ? useUser() : useAdmin();

    return {
      avatarSelect,
      onClick,
      hideDropdown,

      selectOptions,
      profileAvatar,
    };
  },
});
</script>

<style scoped lang="scss">
:global(.avatar-dropdown-popper) {
  width: 205px;
}

.avatar-dropdown {
  &:hover {
    background: none !important;
  }

  :deep(.f-dropdown--title) {
    padding: 0;
  }
}

.item-icon {
  margin-right: 18px;

  :deep(g) {
    opacity: 1;
  }
}

.logout {
  &:hover {
    background: none;
    color: var(--color-error);

    :deep(.text) {
      opacity: 1;
    }
  }
}
</style>
