<template>
  <div class="main-heading">
    <AppText v-if="typeof title === 'string'" size="27px">
      {{ t(title) }}
    </AppText>
    <template v-else>
      <template v-for="(item, i) in title" :key="i">
        <AppText
          size="20px"
          class="heading-title"
          :class="{
            'is-clickable': isSetupWalletTitle(item, i),
          }"
          @click="onTitleClick(item, i)"
        >
          {{ t(item) }}
        </AppText>
        <AppIcon
          v-if="title[i + 1]"
          name="arrow-bracket"
          class="rotate-90"
          opacity="0.4"
          size="18px"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { emitter } from '@/composables/useBus';
import { onTabChange } from '@/composables/usePageLeaving';
import { currentTab } from '@/composables/useWallet';

export default {
  name: 'MainHeading',
  props: {
    title: {
      type: [Array, String],
      default: '',
    },
  },
  setup(props) {
    const isSetupWalletTitle = (item, i) => i === 0 && Boolean(Array.isArray(props.title) && props.title[i + 1] && item === 'sidebar.wallet.setupWallet');

    const onTitleClick = (item, i) => {
      if (isSetupWalletTitle(item, i)) {
        if (currentTab.value !== 'start') {
          const pageLeaveResponse = onTabChange();
          if (pageLeaveResponse) {
            currentTab.value = 'start';
            emitter.emit('page-title', ['sidebar.wallet.setupWallet', '']);
          }
        }
      }
    };

    return { onTitleClick, isSetupWalletTitle };
  },
};
</script>

<style lang="scss" scoped>
.main-heading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.heading-title {
  color: var(--color-black);
  @include transition-base (color);
}

.is-clickable {
  cursor: pointer;
  color: var(--color-black-04);

  &:hover {
    color: var(--color-black);
  }
}
</style>
