import { logout } from '@/api/auth';
import { storeToRefs, useStore } from '@/store';
import { useNotifications } from '@/composables/useNotifications';
import { useSocket } from '@/composables/useSocket';

export const useSessionKilling = () => {
  const store = useStore();
  const { deviceId } = storeToRefs(store);
  const { addSocketListener } = useSocket();
  const { addNotification, t } = useNotifications();

  addSocketListener(`${deviceId.value}-device-deleted`, (message) => {
    if (message.deviceId === deviceId.value) {
      logout({
        pushToHome: true,
        useLogoutEndpoint: false,
      });

      addNotification({
        text: t('merchantDashboard.notification.sessionWasKilled'),
        config: { color: 'error' },
      });
    }
  });
};
